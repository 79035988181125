import React from 'react'
import LogoBlack from './Icons/LogoBlack'
import { Link } from 'gatsby';
import StrapiUrlEncoder from './utils/StrapiUrlEncoder';

const Footer = ({ data }) => {
    const footer_menu = data.edges[0].node.FooterMenu;
    return (
        <footer className="relative">
            <div className="gc m-row-gap-80">
                <div className="span-3 m-1-13">
                    <LogoBlack />
                </div>
                {footer_menu.map((FooterMenuItem, i) => (
                    <div className="span-3 m-1-13" key={i}>
                        <nav className="auto-layout">
                            <h4>{FooterMenuItem.Title}</h4>
                            <ul className="auto-layout auto-layout-10">
                                {FooterMenuItem.MenuItem.map((MenuItem, j) => (
                                    <li key={`footer-menu-item-${i}-${j}`}>
                                        <Link to={MenuItem.Url}>{MenuItem.Title}</Link>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                ))}                
            </div>
            <p className="copyright absolute bottom left">{data.edges[0].node.CopyrightMessage}</p>
        </footer>
    )
}

export default Footer;