import React, { useState } from 'react';
import PropTypes from "prop-types"
import { Link  } from "gatsby"
import Logo from "./Icons/Logo"
import AllProductLinks from "./common/PageBuilder/AllProductLinks"
import LinkTag from "./common/Link"
import SearchModule from '../header/SearchModule';

import StrapiUrlEncoder from "./utils/StrapiUrlEncoder"


const Header = ({ siteTitle, data  }) => {
  const [menuOpen, toggleMenuOpen] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState(null);

  const toggleActiveMenuItem = (i) => {
    i === activeMenuItem ?
      setActiveMenuItem(null) : setActiveMenuItem(i)
  }


  return (
    <header className="flex flex-column">
    <div className="header--shelf flex flex-s-between flex-middle">
      <Link to="/">
        <Logo />
      </Link>

      {/* {data.edges[0].node.BrokerPortalText != null && data.edges[0].node.BrokerPortalUrl != null ?
        <Link target="_blank" rel="noopener noreferrer" to={data.edges[0].node.BrokerPortalUrl}>
          <button className="primary green">{data.edges[0].node.BrokerPortalText}</button>
        </Link>
      :null} */}
      
      <div className="country-selector flex flex-s-between desktop-only">
        <p className="small medium">New Zealand</p>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842854 10.1217 0 8 0ZM14.4 5.33333H12.0293C11.8171 3.98183 11.312 2.69307 10.5493 1.55733C11.41 1.89903 12.1939 2.40899 12.8551 3.05736C13.5163 3.70573 14.0415 4.47949 14.4 5.33333ZM14.9333 8C14.9314 8.53912 14.866 9.07613 14.7387 9.6H12.1813C12.2951 8.53636 12.2951 7.46363 12.1813 6.4H14.7387C14.866 6.92386 14.9314 7.46088 14.9333 8ZM1.06667 8C1.06865 7.46088 1.13398 6.92386 1.26134 6.4H3.81867C3.70489 7.46363 3.70489 8.53636 3.81867 9.6H1.26134C1.13398 9.07613 1.06865 8.53912 1.06667 8ZM4.8 8C4.79992 7.46545 4.8293 6.93131 4.888 6.4H7.46667V9.6H4.888C4.8293 9.06869 4.79992 8.53454 4.8 8ZM8.53333 1.17867C9.54134 1.584 10.5067 3.09867 10.9333 5.33333H8.53333V1.17867ZM7.46667 1.17867V5.33333H5.06667C5.49334 3.09867 6.45867 1.584 7.46667 1.17867ZM7.46667 10.6667V14.8213C6.45867 14.416 5.49334 12.9013 5.06667 10.6667H7.46667ZM8.53333 14.8213V10.6667H10.9333C10.5067 12.9013 9.54134 14.416 8.53333 14.8213ZM8.53333 9.6V6.4H11.112C11.2293 7.46344 11.2293 8.53656 11.112 9.6H8.53333ZM5.45067 1.55733C4.68799 2.69307 4.18286 3.98183 3.97067 5.33333H1.6C1.95849 4.47949 2.4837 3.70573 3.14489 3.05736C3.80608 2.40899 4.58997 1.89903 5.45067 1.55733ZM1.6 10.6667H3.968C4.18019 12.0182 4.68532 13.3069 5.448 14.4427C4.5878 14.1007 3.80442 13.5906 3.1437 12.9422C2.48298 12.2939 1.95818 11.5203 1.6 10.6667ZM10.5493 14.4427C11.312 13.3069 11.8171 12.0182 12.0293 10.6667H14.4C14.0415 11.5205 13.5163 12.2943 12.8551 12.9426C12.1939 13.591 11.41 14.101 10.5493 14.4427Z" fill="#474848"/>
        </svg>
      </div>


      <div className="mobile-only mobile-navigation">
        <button type="button" role="navigation" className="no-background" name="MobileMenu" onClick={() => toggleMenuOpen(!menuOpen)}>
          {menuOpen ? 
            <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.9286 4.92882C8.51438 4.34304 9.46413 4.34304 10.0499 4.92882L22.0707 16.9496C22.6565 17.5354 22.6565 18.4852 22.0707 19.071C21.4849 19.6567 20.5352 19.6567 19.9494 19.071L7.9286 7.05014C7.34281 6.46436 7.34281 5.51461 7.9286 4.92882Z" fill="#474848"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M7.92882 19.0714C7.34304 18.4856 7.34304 17.5359 7.92882 16.9501L19.9496 4.92927C20.5354 4.34348 21.4852 4.34348 22.071 4.92927C22.6567 5.51505 22.6567 6.4648 22.071 7.05059L10.0501 19.0714C9.46436 19.6572 8.51461 19.6572 7.92882 19.0714Z" fill="#474848"/>
            </svg>  
          :
            <svg width="30" height="24" viewBox="0 0 30 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5 5C5 4.17157 5.67157 3.5 6.5 3.5H23.5C24.3284 3.5 25 4.17157 25 5C25 5.82843 24.3284 6.5 23.5 6.5H6.5C5.67157 6.5 5 5.82843 5 5Z" fill="#474848"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5 12C5 11.1716 5.67157 10.5 6.5 10.5H23.5C24.3284 10.5 25 11.1716 25 12C25 12.8284 24.3284 13.5 23.5 13.5H6.5C5.67157 13.5 5 12.8284 5 12Z" fill="#474848"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5 19C5 18.1716 5.67157 17.5 6.5 17.5H23.5C24.3284 17.5 25 18.1716 25 19C25 19.8284 24.3284 20.5 23.5 20.5H6.5C5.67157 20.5 5 19.8284 5 19Z" fill="#474848"/>
            </svg>
          }
        </button>
      </div>
    </div>
    <div className="mobile-menu-container header--shelf flex flex-s-between flex-middle" data-menu-open={menuOpen}>
      <nav className="main-menu">
        <ul className="flex flex-r m-flex-column">
        {data.edges[0].node.NavigationTab.map((NavigationTab, i) => (
          <li 
          className={`parent-link flex flex-r flex-s-between ${ activeMenuItem === i ? 'open' : '' } ${NavigationTab.subLinks.length > 0 || NavigationTab.LinkAllProductPages === true ? 'has-child-links' : ''}`}
          style={{ "--primary-colour":  NavigationTab.Colour }}
          key={i}
          onClick={() => toggleActiveMenuItem(i)}
          >
            {NavigationTab.tabLink ?
            <Link to={`/pages/${StrapiUrlEncoder(NavigationTab.tabLink.Slug)}`}>{ NavigationTab.Title }</Link>
            : NavigationTab.Title === "News" ?
            <Link to="/news">News</Link>
            :
            <p>{NavigationTab.Title}</p>
            }
            {NavigationTab.subLinks.length > 0 || NavigationTab.LinkAllProductPages === true ?
            <div className="mobile-dropdown-indicator mobile-only flex flex-center flex-middle">
              <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.999999 1L5 5L9 1" stroke="#474848" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </div>
            : null }
            {NavigationTab.subLinks.length > 0 || NavigationTab.LinkAllProductPages === true ?
            <nav className="hidden-menu">
              <div className="gc">
                {NavigationTab.subLinks ?
                  NavigationTab.subLinks.map((link, i) => (
                    <div className="span-3 m-span-12">
                      <Link to={`/pages/${link.Slug}`}><h4>{link.Title}</h4></Link>
                      {NavigationTab.ChildLinksColumn ?
                        <div className="sublink-container desktop-only flex flex-column auto-layout auto-layout-20">
                          {NavigationTab.ChildLinksColumn.map(( childLink, j  ) => (
                            childLink.ColumnNumber === i + 1 ?
                              <Link to={childLink.LinkUrl}>{ childLink.LinkTitle }</Link>
                              : null
                          ))}
                        </div>
                      :null}
                    </div>
                  ))
                : null}
                {NavigationTab.LinkAllProductPages === true ?
                    <>
                      <AllProductLinks additionalClass="span-3 m-span-12" fontWeight="regular" />    
                      <LinkTag 
                        text="View all"
                        url="/pages/our-products"
                        icon="chevron"
                        additionalClass="d-1-4 m-1-13 view-all"
                      />
                    </>         
                : null}
                
                {/* {NavigationTab.NavigationGroupSection.map((NavigationGroup, j) => (
                  <div className="span-3" key={`${i}-${j}`}>
                    {NavigationGroup.Title ? 
                      <h4>{NavigationGroup.Title}</h4>
                    : null}
                    <ul>
                    {NavigationGroup.pages.length > 0 ? 
                      NavigationGroup.pages.map((NavigationGroupPage, k) => (
                        <li key={`${i}-${j}-${k}`}>
                          <Link to={`/pages/${StrapiUrlEncoder(NavigationGroupPage.Title)}`}>{NavigationGroupPage.Title}</Link>
                        </li>
                      ))
                    : null }
                    {NavigationGroup.products.length > 0 ? 
                      NavigationGroup.products.map((NavigationGroupPage, k) => (
                        <li key={`${i}-${j}-${k}`}>
                          <Link to={`/products/${StrapiUrlEncoder(NavigationGroupPage.Title)}`}>{NavigationGroupPage.Title}</Link>
                        </li>
                      ))
                    : null }
                    </ul>
                  </div>
                ))} */}

                
              </div>
            </nav>
              :null}
          </li>
        ))}
        </ul>
      </nav>
      <div>
        <SearchModule />
      </div>
    </div>
  </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
