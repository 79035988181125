/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

 import * as React from "react"
 import PropTypes from "prop-types"
 import { useStaticQuery, graphql } from "gatsby"
 
 import Header from "./header"
 import Footer from "./Footer"
 import { Helmet } from 'react-helmet'
 import "../styles/main.scss"
 
 const Layout = ({ children }) => {
   const data = useStaticQuery(graphql`
     query SiteTitleQuery {
       site {
         siteMetadata {
           title
         }
       },
       allStrapiSiteNavigation {
         edges {
           node {
             id
             NavigationTab
           }
         }
       },
       allStrapiFooter {
         edges {
           node {
             id
             FooterMenu
             CopyrightMessage             
           }
         }
       }
     }
   `)
 
   return (
     <>
     <Helmet>
       <meta http-equiv="content-type" content="text/html; charset=utf-8" />
       <link rel="shortcut icon" href="https://res.cloudinary.com/new-territory-delta/image/upload/v1636936323/Delta_arrow_icon_654842d3a8.png" />
       <script src="https://www.googletagmanager.com/gtag/js?id=UA-81907514-1" />
       <script src="/gtagConfig.js" />
     </Helmet>
       <Header siteTitle={data.site.siteMetadata?.title || `Title`} data={data.allStrapiSiteNavigation }/>
         <main>{children}</main>
       <Footer data={data.allStrapiFooter} />
     </>
   )
 }
 
 Layout.propTypes = {
   children: PropTypes.node.isRequired,
 }
 
 export default Layout
 